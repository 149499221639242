import React from "react"
import TemplatePageRecursos from "@components/pageRecursos/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>E-books, guías & más recursos de Beex Latam [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Encuentra los recursos más actualizados sobre contact center, chatbots, atención al cliente, omnicanalidad, campañas inbound y outbound, y mucho más."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="E-books, guías & más recursos de Beex Latam"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/`}
      />
        <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Encuentra los recursos más actualizados sobre contact center, chatbots, atención al cliente, omnicanalidad, campañas inbound y outbound, y mucho más."
      />
    </Helmet>
    <TemplatePageRecursos location={location} />
  </div>
)

export default IndexPage
